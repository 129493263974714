import styled from 'styled-components'
import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'

import UserConfigContext from '~Contexts/UserConfig.context'
import { UserConfigVisitorType } from '~Types/UserConfig.type'
import Menu from './Menu'

interface CondolenceProps {
  downloadFile: any
  condolence: any
  setReloadCondolences: any
  siteUrl: string
}
const Index = ({
  condolence,
  setReloadCondolences,
  siteUrl,
  downloadFile,
}: CondolenceProps) => {
  const { isManager, visitor } = useContext(UserConfigContext)

  const isUserAllowed = (
    isManager: boolean,
    visitor: UserConfigVisitorType | undefined,
    condolence: any
  ) => {
    return isManager || (!!visitor && visitor.UUID === condolence.visitorUUID)
  }

  if (condolence.imageUrl) {
    return (
      <SCard container direction="column">
        <Grid container direction="row">
          <SImageContainer item xs={12}>
            <img src={condolence.imageUrl} alt="Imagen de la condolencia" />
          </SImageContainer>
        </Grid>

        {condolence.message && (
          <STitleContainer item xs={12} style={{ marginTop: 24 }}>
            {condolence.message.trim()}
          </STitleContainer>
        )}

        <Grid
          container
          direction="row"
          style={{ marginBottom: 18, marginTop: 36 }}
        >
          <Grid item xs={2} />
          <SVisitorFullnameContainer item xs={8}>
            {condolence.visitorFullname}
          </SVisitorFullnameContainer>
          <MenuContainer item xs={2}>
              <Menu
                condolence={condolence}
                setReloadCondolences={setReloadCondolences}
                siteUrl={siteUrl}
                downloadFile={downloadFile}
                isUserAllowed={isUserAllowed(isManager, visitor, condolence)}
              />
          </MenuContainer>
        </Grid>
      </SCard>
    )
  } else {
    return (
      <SCard container direction="column">
        <Grid
          container
          direction="row"
          style={{ marginBottom: 36, marginTop: 36 }}
        >
          <Grid item xs={1} />
          <SMessageContainer item xs={10}>
            "{condolence.message.trim()}"
          </SMessageContainer>
          <Grid item xs={1} />
        </Grid>
        <Grid container direction="row" style={{ marginBottom: 18 }}>
          <Grid item xs={2} />
          <SVisitorFullnameContainer item xs={8}>
            {condolence.visitorFullname}
          </SVisitorFullnameContainer>
          <MenuContainer item xs={2}>
              <Menu
                condolence={condolence}
                setReloadCondolences={setReloadCondolences}
                siteUrl={siteUrl}
                downloadFile={downloadFile}
                isUserAllowed={isUserAllowed(isManager, visitor, condolence)}
              />
          </MenuContainer>
        </Grid>
      </SCard>
    )
  }
}

const SCard = styled(Grid)`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`

const SImageContainer = styled(Grid)`
  text-align: center;

  img {
    height: inherit;
    max-height: inherit;
    max-width: inherit;
    width: inherit;
  }
`

const SMessageContainer = styled(Grid)`
  color: #333333;
  font-family: 'Playfair Display';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 22px;
  text-align: center;
`

const MenuContainer = styled(Grid)`
  padding-right: 10px;
  text-align: end;
`

const STitleContainer = styled(Grid)`
  color: #000000;
  font-family: 'Playfair Display';
  font-size: 24px;
  letter-spacing: 0.17px;
  line-height: 20px;
  text-align: center;
`

const SVisitorFullnameContainer = styled(Grid)`
  opacity: 0.5;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Light';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.1px;
  line-height: 20px;
  text-align: center;
`

export default Index
