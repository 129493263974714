import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Grid } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import DownloadIcon from '@material-ui/icons/CloudDownload';

import LoaderContext from '~Contexts/Loader.context'
import SnackbarContext from '~Contexts/Snackbar.context'
import UserConfigContext from '~Contexts/UserConfig.context'
import { useWidth } from '~Hooks/useWidth'
import routes from '~Routes/routes'
import { getSiteCondolences, getPdfAllCondolences } from '~Services/index'
import ReviewDialog from '~Screens/View/components/ReviewDialog'
import AddCondolenceMenu from './AddMenu'
import Condolence from './condolence'
import AddCondolenceImageDialog from './condolence/AddImageDialog'
import AddCondolenceMessageDialog from './condolence/AddMessageDialog'
import Masonry from './Masonry'
import NoCondolences from './NoCondolences'

const fileSaver = require('file-saver');

interface CondolencesProps {
  decease: any
}
const Condolences = ({ decease }: CondolencesProps) => {
  const history = useHistory()
  const { name: nameUrl, code: codeUrl } = useParams()
  const { hideLoader, showLoader } = useContext(LoaderContext)
  const { isManager, visitor } = useContext(UserConfigContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const width = useWidth()

  const btnDownloadRef = React.useRef<HTMLButtonElement>(null)
  const [btnDisabled, setBtnDisabled] = React.useState(false)


  const [condolences, setCondolences] = React.useState([])
  const [reloadCondolences, setReloadCondolences] = React.useState(true)

  const [openAddMessageDialog, setOpenAddMessageDialog] = React.useState(false)
  const handleSuccessfulSubmitAddMessageDialog = () => {
    handleCloseAddMessageDialog()
    window.scrollTo(0, 0)
    setReloadCondolences(true)
    openSnackbar('La condolencia se ha añadido correctamente', 'success')
    setTimeout(() => {
      if (visitor && !visitor.reviewCompleted) {
        handleOpenReviewDialog()
      }
    }, 2000)
  }
  const handleCloseAddMessageDialog = () => {
    setOpenAddMessageDialog(false)
  }
  const handleOpenAddMessageDialog = () => {
    setOpenAddMessageDialog(true)
  }

  const [openAddImageDialog, setOpenAddImageDialog] = React.useState(false)
  const handleSuccessfulSubmitAddImageDialog = () => {
    handleCloseAddImageDialog()
    window.scrollTo(0, 0)
    setReloadCondolences(true)
    openSnackbar('La condolencia se ha añadido correctamente', 'success')
    setTimeout(() => {
      if (visitor && !visitor.reviewCompleted) {
        handleOpenReviewDialog()
      }
    }, 2000)
  }
  const handleCloseAddImageDialog = () => {
    setOpenAddImageDialog(false)
  }
  const handleOpenAddImageDialog = () => {
    setOpenAddImageDialog(true)
  }

  const [openReviewDialog, setOpenReviewDialog] = React.useState(false)
  const handleSuccessfulSubmitReviewDialog = () => {
    handleCloseReviewDialog()
    openSnackbar('¡Gracias por su opinión!', 'success')
  }
  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false)
  }
  const handleOpenReviewDialog = () => {
    setOpenReviewDialog(true)
  }

  const downloadFileImage = (condolence: any) => {
    showLoader()
    let imageType = condolence.imageMimetype.split('/')[1].toLowerCase();
    imageType = imageType.toLowerCase() === "jpeg" ? "jpg" : imageType;
    const fileName = `${condolence.UUID}.${imageType}`;
    console.log(condolence.imageUrl + ' --> ' + fileName)
    setTimeout(function() {
      fileSaver.saveAs(condolence.imageUrl, fileName);
      hideLoader()
    }, 800);
    setBtnDisabled(false);
  }

  const handleDownloadButton = () => {
    showLoader()

    getPdfAllCondolences(decease.site.url)
        .then((response) => {
          if (!response.data.errors.length && !!response.data.data && !!response.data.data.pdfUrl) {
              const link = document.createElement( 'a' );
              // @ts-ignore
              link.href = response.data.data.pdfUrl;
              link.click();
          }
        })
        .catch((error) => {})
        .finally(() => {
          hideLoader()
        })
  }


  // Open review dialog after 10 seconds if review is not completed
  // useEffect(() => {
  //   let timer = setTimeout(() => {
  //     if (visitor && !visitor.reviewCompleted) {
  //       handleOpenReviewDialog()
  //     }
  //   }, 10000)
  //
  //   // this will clear Timeout when component unmount like in willComponentUnmount
  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [visitor])

  useEffect(
    () => {
      if (reloadCondolences) {
        showLoader()

        getSiteCondolences(decease.site.url)
          .then((response) => {
            if (response.data.errors.length) {
              // return setIsPageNotFoundRedirect(true)
            } else {
              setCondolences(response.data.data.condolences)
            }
          })
          .catch((error) => {})
          .finally(() => {
            hideLoader()
            setReloadCondolences(false)
          })
      }

      try {
        const supported = !!new Blob();
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reloadCondolences]
  )

  return (
    <SGridContainer container direction="column">
      <Grid
        container
        direction="row"
        justify="center"
        style={{ marginBottom: 150 }}
      >
        {!condolences || !condolences.length ? (
          <SNoCondolencesContainer item xs={12} style={{ marginTop: 24 }}>
            <NoCondolences />
          </SNoCondolencesContainer>
        ) : (
          ''
        )}

        {condolences &&
        condolences.length &&
        width !== 'xs' &&
        width !== 'sm' ? (
          <Grid item xs={12} md={12}>
            <Grid container direction="row">
              <Grid item md={2} />
              <Grid item md={8}>
                <Masonry
                  decease={decease}
                  downloadFile={downloadFileImage}
                  condolences={condolences}
                  key={condolences.length}
                  setReloadCondolences={setReloadCondolences}
                />
              </Grid>
              <Grid item md={2} />
            </Grid>
          </Grid>
        ) : (
          ''
        )}

        {condolences && condolences.length && (width === 'xs' || width === 'sm')
          ? condolences.map((data: any) => {
              return (
                <SCondolenceContainer
                  item
                  key={data.UUID}
                  style={{ marginTop: 24, paddingLeft: 20, paddingRight: 20 }}
                >
                  <Condolence
                    condolence={data}
                    setReloadCondolences={setReloadCondolences}
                    siteUrl={decease.site.url}
                    downloadFile={downloadFileImage}
                  />
                </SCondolenceContainer>
              )
            })
          : ''}
      </Grid>

      {/*Manager can't add condolences*/}
      {isManager ? (
        <SFixedButtonContainer>
          <Button
            color="primary"
            endIcon={<CreateIcon />}
            onClick={() => {
              if (nameUrl && codeUrl) {
                let route = routes.edition.path
                route = route.replace(':name', nameUrl)
                route = route.replace(':code', codeUrl)
                history.push(route)
              }
            }}
            type="submit"
            variant="contained"
          >
            VOLVER AL EDITOR
          </Button>
          <SButton
              onClick={handleDownloadButton}
              variant="contained" color="primary" size="large" endIcon={<DownloadIcon />}>
            Descargar condolencias
          </SButton>
        </SFixedButtonContainer>
      ) : (
        <SFixedButtonContainer>
          <AddCondolenceMenu
            color={decease.site.color}
            handleOpenAddMessageDialog={handleOpenAddMessageDialog}
            handleOpenAddImage={handleOpenAddImageDialog}
          />


        <SButton
          onClick={handleDownloadButton}
          variant="contained" color="primary" size="large" endIcon={<DownloadIcon />}>
            Descargar condolencias
        </SButton>

        </SFixedButtonContainer>
      )}

      <AddCondolenceMessageDialog
        decease={decease}
        handleClose={handleCloseAddMessageDialog}
        handleSuccessfulSubmit={handleSuccessfulSubmitAddMessageDialog}
        open={openAddMessageDialog}
      />

      <AddCondolenceImageDialog
        decease={decease}
        handleClose={handleCloseAddImageDialog}
        handleSuccessfulSubmit={handleSuccessfulSubmitAddImageDialog}
        open={openAddImageDialog}
      />

      <ReviewDialog
        decease={decease}
        handleClose={handleCloseReviewDialog}
        handleSuccessfulSubmitReviewDialog={handleSuccessfulSubmitReviewDialog}
        open={openReviewDialog}
      />
    </SGridContainer>
  )
}

const SFixedButtonContainer = styled.div`
  bottom: 37px;
  position: fixed;
  text-align: center;
  width: auto;
  flex-direction: column;
  display: flex;
`

const SCondolenceContainer = styled(Grid)`
  max-width: 600px;
  width: 100%;
`

const SGridContainer = styled(Grid)`
  align-items: center;
  background-color: #f5f5f5;
  flex: 1;
`

const SNoCondolencesContainer = styled(Grid)`
  width: 100%;
`
const SButton = styled(Button)`
  margin-top: 10px;
`

export default Condolences
