import axios from 'axios'

import { getCustomHeaders } from '~Utils/index'

export const deleteSiteCondolence = (
  siteUrl: string,
  condolenceUUID: string
) => {
  return axios.delete(
    process.env.REACT_APP_API_DOMAIN + '/api/site/condolence',
    {
      headers: getCustomHeaders(),
      params: {
        siteUrl: siteUrl,
        UUID: condolenceUUID,
      },
      withCredentials: true,
    }
  )
}

export const getSite = (siteUrl: string) => {
  return axios.get(process.env.REACT_APP_API_DOMAIN + '/api/site', {
    headers: getCustomHeaders(),
    params: {
      siteUrl: siteUrl,
    },
    withCredentials: true,
  })
}

export const getSiteCondolences = (siteUrl: string) => {
  return axios.get(process.env.REACT_APP_API_DOMAIN + '/api/site/condolences', {
    headers: getCustomHeaders(),
    params: {
      siteUrl: siteUrl,
    },
    withCredentials: true,
  })
}

export const getPdfAllCondolences = (siteUrl: string) => {
  return axios.get(process.env.REACT_APP_API_DOMAIN + '/api/site/condolences-pdf', {
    headers: getCustomHeaders(),
    params: {
      siteUrl: siteUrl,
    },
    withCredentials: true,
  })
}

export const getSiteEdition = (siteUrl: string) => {
  return axios.get(process.env.REACT_APP_API_DOMAIN + '/api/site/edition', {
    headers: getCustomHeaders(),
    params: {
      siteUrl: siteUrl,
    },
    withCredentials: true,
  })
}

export const getSiteVerify = (siteUrl: string) => {
  return axios.get(process.env.REACT_APP_API_DOMAIN + '/api/site/verify', {
    headers: getCustomHeaders(),
    params: {
      siteUrl: siteUrl,
    },
  })
}

export const postSiteAvatar = (data: any) => {
  return axios.post(
    process.env.REACT_APP_API_DOMAIN + '/api/site/avatar',
    data,
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}

export const postSiteCondolenceImage = (data: any) => {
  return axios.post(
    process.env.REACT_APP_API_DOMAIN + '/api/site/condolence/image',
    data,
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}

export const postSiteCondolenceMessage = (message: string, siteUrl: string) => {
  return axios.post(
    process.env.REACT_APP_API_DOMAIN + '/api/site/condolence/message',
    {
      message: message,
      siteUrl: siteUrl,
    },
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}

export const postSiteLogin = (password: string, siteUrl: string) => {
  return axios.post(
    process.env.REACT_APP_API_DOMAIN + '/api/site/login',
    {
      password: password,
      siteUrl: siteUrl,
    },
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}

export const postSiteReview = (
  opinion: string,
  rating: number,
  robinsonThird: boolean,
  siteUrl: string
) => {
  return axios.post(
    process.env.REACT_APP_API_DOMAIN + '/api/site/review',
    {
      opinion: opinion,
      rating: rating,
      robinsonThird: robinsonThird,
      siteUrl: siteUrl,
    },
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}

export const postSiteNewManagerLogin = (
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  phone: string,
  siteUrl: string
) => {
  return axios.post(
    process.env.REACT_APP_API_DOMAIN + '/api/site/new-manager/login',
    {
      email: email,
      firstname: firstname,
      lastname: lastname,
      password: password,
      phone: phone,
      siteUrl: siteUrl,
    },
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}

export const postSiteVisitor = (
  email: string,
  fullname: string,
  robinsonThird: boolean,
  phone: string,
  privacyPolicy: boolean,
  siteUrl: string
) => {
  return axios.post(
    process.env.REACT_APP_API_DOMAIN + '/api/site/visitor',
    {
      email: email,
      fullname: fullname,
      robinsonThird: robinsonThird,
      phone: phone,
      privacyPolicy: privacyPolicy,
      siteUrl: siteUrl,
    },
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}

export const putSite = (siteUrl: string, decease: any) => {
  return axios.put(
    process.env.REACT_APP_API_DOMAIN + '/api/site',
    {
      decease: decease,
      siteUrl: siteUrl,
    },
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}

export const putSiteDeactivate = (siteUrl: string) => {
  return axios.put(
    process.env.REACT_APP_API_DOMAIN + '/api/site/deactivate',
    {
      siteUrl: siteUrl,
    },
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}

export const putSitePublish = (publish: boolean, siteUrl: string) => {
  return axios.put(
    process.env.REACT_APP_API_DOMAIN + '/api/site/publish',
    {
      publish: publish,
      siteUrl: siteUrl,
    },
    {
      headers: getCustomHeaders(),
      withCredentials: true,
    }
  )
}
